import React from "react"
import BrochureScreen from "../components/brochure/BrochureScreen"
import SEO from "../components/seo"

const brochureTemplate = ({ pageContext: { resultData } }) => {
  return (
    <>
      <SEO
        title={resultData.evento.Titulo}
        image={resultData.evento.ImagenDestacada}
        pathname={resultData.pathname}
      />
      <BrochureScreen resultData={resultData} />
    </>
  )
}

export default brochureTemplate
