import moment from "moment"
import "moment/locale/es"
moment.locale("es")

const ordenarFechasEvento = evento => {
  const hoy = moment(new Date())

  const fechasOrdenadas = evento.Fechas.filter(f => {
    if (f.Fecha) {
      if (moment(f.Fecha).isSameOrAfter(hoy, "day")) {
        return true
      } else {
        return false
      }
    } else {
      if (
        hoy.isBetween(moment(f.Inicio), moment(f.Fin), "day", "[]") ||
        moment(f.Inicio).isSameOrAfter(hoy, "day")
      ) {
        return true
      } else {
        return false
      }
    }
  }).sort((a, b) => {
    if (a.Fecha && b.Fecha) {
      if (moment(a.Fecha).isBefore(moment(b.Fecha), "day")) {
        return -1
      }
      if (moment(a.Fecha).isAfter(moment(b.Fecha), "day")) {
        return 1
      }
    } else if (a.Fecha) {
      if (moment(a.Fecha).isBefore(moment(b.Inicio), "day")) {
        return -1
      }
      if (moment(a.Fecha).isAfter(moment(b.Inicio), "day")) {
        return 1
      }
    } else if (b.Fecha) {
      if (moment(a.Inicio).isBefore(moment(b.Fecha), "day")) {
        return -1
      }
      if (moment(a.Inicio).isAfter(moment(b.Fecha), "day")) {
        return 1
      }
    } else {
      if (moment(a.Inicio).isBefore(moment(b.Inicio), "day")) {
        return -1
      }
      if (moment(a.Inicio).isAfter(moment(b.Inicio), "day")) {
        return 1
      }
    }
    return 0
  })

  return { ...evento, Fechas: fechasOrdenadas }
}

export default ordenarFechasEvento
