import React from "react";
import ordenarFechasEvento from "../../helpers/ordenarFechasEvento";
import moment from "moment";
import "moment/locale/es";

const BrochureScreen = ({ resultData }) => {
  moment.locale("es");

  const { evento, variablesEntorno } = resultData;

  const eventoOrdenado = ordenarFechasEvento(evento);

  const {
    Descripcion,
    // OrganizadoPor,
    MasInformacion,
    tipo_evento,
    Titulo,
    Fechas,
    Lugar,
    Costo,
    CostoPersonalizado,
    ImagenDestacada,
    slug,
    LinkInscripcion,
  } = eventoOrdenado;

  let m1 = [];
  let m2 = [];
  if (MasInformacion.length > 0) {
    m1 = MasInformacion.slice(0, Math.ceil(MasInformacion.length / 2));
    m2 = MasInformacion.slice(
      Math.ceil(MasInformacion.length / 2),
      MasInformacion.length
    );
  }

  const proximaFecha = Fechas[0];

  let calendarInicio = null;
  let calendarFin = null;
  if (proximaFecha.Fecha) {
    calendarInicio = moment(
      moment(proximaFecha.Fecha).format("YYYY-MM-DD ") + proximaFecha.Inicio
    )
      // .utc()
      .format("YYYYMMDDTHHmmss");

    calendarFin = moment(
      moment(proximaFecha.Fecha).format("YYYY-MM-DD ") + proximaFecha.Fin
    )
      .add(1, "day")
      // .utc()
      .format("YYYYMMDDTHHmmss");
  } else {
    calendarInicio = moment(proximaFecha.Inicio)
      // .utc()
      // .toISOString()
      .format("YYYYMMDDTHHmmss");

    calendarFin = moment(proximaFecha.Fin)
      .add(1, "day")
      // .utc()
      .format("YYYYMMDDTHHmmss");
  }

  return (
    <div>
      <table>
        <tbody>
          <tr>
            <td height="20px"></td>
          </tr>
        </tbody>
      </table>

      <table
        border="0"
        cellSpacing="0"
        cellPadding="0"
        align="center"
        width="650"
        style={{
          width: "650px !important",
          backgroundColor: "#FFFFFF !important",
          border: "1px solid #c5c5c5",
          borderRadius: "3px",
        }}
      >
        <tbody>
          {/* <!-- header --> */}
          <tr>
            <td valign="top" style={{ lineHeight: "0px" }} className="testclass">
              <a
                href="https://agenda.pucp.edu.pe"
                target="_blank"
                rel="noreferrer"
                style={{ lineHeight: "0px", display: "block", marginTop:"25px", marginBottom:"25px", marginLeft:"25px" }}
              >
                <img
                  alt="texto"
                  border="0"
                  style={{ height:"60px" }}
                  src="https://s3.amazonaws.com/files.pucp.edu.pe/pucp-general/img-header/logo-agenda-brochure.jpg"
                />
              </a>
            </td>
          </tr>
          {/* <!-- fin header --> */}

          {/* <!--cuerpo --> */}
          <tr style={{ borderCollapse: "collapse" }}>
            <td
              valign="top"
              style={{ background: "#ffffff", margin: 0, padding: 0 }}
            >
              <table
                border="0"
                cellPadding="0"
                cellSpacing="0"
                width="100%"
                style={{ borderCollapse: "collapse" }}
              >
                <tbody>
                  {/* <!-- DESTACADO AZUL--> */}

                  {/* <!-- tag --> */}
                  <tr style={{ borderCollapse: "collapse" }}>
                    <td
                      style={{ margin: 0, padding: 0, background: "#001056" }}
                    >
                      <table
                        border="0"
                        cellPadding="0"
                        cellSpacing="0"
                        width="92%"
                        align="center"
                        style={{
                          fontFamily: "Arial, sans-serif",
                          color: "#ffffff",
                          fontSize: "12px",
                          lineHeight: "23px",
                          borderCollapse: "collapse",
                        }}
                      >
                        <tbody>
                          <tr>
                            <td
                              valign="top"
                              height="5"
                              style={{ margin: 0, padding: 0 }}
                            ></td>
                          </tr>
                          <tr>
                            <td
                              valign="top"
                              width="48%"
                              style={{
                                fontFamily: "Arial, sans-serif",
                                fontSize: "14px",
                                lineHeight: "23px",
                                borderRadius: "5px",
                                margin: 0,
                                padding: 0,
                              }}
                            >
                              <table
                                border="0"
                                cellPadding="0"
                                cellSpacing="0"
                                align="center"
                                style={{
                                  display: "block",
                                  borderCollapse: "collapse",
                                }}
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      colSpan="3"
                                      height="20"
                                      style={{ margin: 0, padding: 0 }}
                                    ></td>
                                  </tr>
                                  <tr
                                    style={{
                                      display: "block",
                                      margin: 0,
                                      padding: 0,
                                      width: "100%",
                                    }}
                                  >
                                    <td style={{ margin: 0, padding: 0 }}>
                                      <table
                                        border="0"
                                        cellPadding="0"
                                        cellSpacing="0"
                                        align="left"
                                        style={{
                                          minWidth: "100px",
                                          borderRadius: "0px",
                                          background: "#00C5D8",
                                        }}
                                      >
                                        <tbody>
                                          {tipo_evento && (
                                            <>
                                              {tipo_evento.Nombre && (
                                                <>
                                                  <tr>
                                                    <td
                                                      colSpan="3"
                                                      height="1"
                                                    ></td>
                                                  </tr>
                                                  <tr>
                                                    <td
                                                      height="18"
                                                      width="10"
                                                    ></td>
                                                    <td
                                                      style={{
                                                        fontFamily: "Arial",
                                                        fontSize: "12px",
                                                        fontWeight: 600,
                                                        color: "#001056",
                                                        lineHeight: "12px",
                                                        textAlign: "center",
                                                        textTransform:
                                                          "uppercase",
                                                        verticalAlign: "middle",
                                                      }}
                                                    >
                                                      {tipo_evento.Nombre}
                                                    </td>
                                                    <td
                                                      height="18"
                                                      width="10"
                                                      style={{
                                                        margin: 0,
                                                        padding: 0,
                                                      }}
                                                    ></td>
                                                  </tr>
                                                </>
                                              )}
                                            </>
                                          )}
                                          <tr>
                                            <td
                                              colSpan="3"
                                              height="0"
                                              style={{ margin: 0, padding: 0 }}
                                            ></td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>

                  {/* <!-- fin tag --> */}

                  {/* <!-- titulo --> */}
                  <tr style={{ borderCollapse: "collapse" }}>
                    <td
                      style={{ margin: 0, padding: 0, background: "#001056" }}
                    >
                      <table
                        border="0"
                        cellPadding="0"
                        cellSpacing="0"
                        width="92%"
                        align="left"
                        style={{
                          fontFamily: "Arial, sans-serif",
                          lineHeight: "23px",
                          borderCollapse: "collapse",
                        }}
                      >
                        <tbody>
                          <tr style={{ borderCollapse: "collapse" }}>
                            <td
                              valign="top"
                              height="18"
                              style={{ margin: 0, padding: 0 }}
                            ></td>
                          </tr>

                          {Titulo && (
                            <tr style={{ borderCollapse: "collapse" }}>
                              <td
                                valign="bottom"
                                style={{
                                  paddingBottom: 0,
                                  marginBottom: 0,
                                  paddingTop: 0,
                                  marginTop: 0,
                                  color: "#ffffff",
                                  fontSize: "27px",
                                  lineHeight: "32px",
                                  fontFamily: "Arial",
                                  fontWeight: 600,
                                  paddingLeft: "25px",
                                  textAlign: "left",
                                  paddingRight: "25px",
                                }}
                              >
                                {Titulo}
                              </td>
                            </tr>
                          )}
                          <tr
                            style={{
                              borderCollapse: "collapse",
                              background: "#001056",
                            }}
                          >
                            <td
                              valign="bottom"
                              height="18"
                              style={{
                                margin: 0,
                                padding: 0,
                                background: "#001056",
                                border: "none",
                              }}
                            ></td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>

                  {/* <!-- fin de titulo --> */}

                  <tr style={{ borderCollapse: "collapse" }}>
                    <td
                      style={{ margin: 0, padding: 0, background: "#001056" }}
                    >
                      <table
                        border="0"
                        cellPadding="0"
                        cellSpacing="0"
                        width="92%"
                        align="center"
                        style={{
                          fontFamily: "Arial, sans-serif",
                          color: "#ffffff",
                          fontSize: "13px",
                          lineHeight: "23px",
                          borderCollapse: "collapse",
                        }}
                      >
                        <tbody>
                          <tr style={{ borderCollapse: "collapse" }}>
                            <td style={{ margin: 0, padding: 0 }}>
                              <table
                                border="0"
                                cellPadding="0"
                                cellSpacing="0"
                                width="100%"
                                align="top"
                                style={{ borderCollapse: "collapse" }}
                              >
                                <tbody>
                                  {/* <!-- info --> */}
                                  <tr style={{ display: "flex" }}>
                                    <td
                                      valign="top"
                                      width="48%"
                                      style={{
                                        fontFamily: "Arial, sans-serif",
                                        fontSize: "14px",
                                        lineHeight: "23px",
                                        borderRadius: "5px",
                                        margin: 0,
                                        padding: 0,
                                      }}
                                    >
                                      <table
                                        border="0"
                                        cellPadding="0"
                                        cellSpacing="0"
                                        align="center"
                                        style={{
                                          display: "block",
                                          borderCollapse: "collapse",
                                        }}
                                      >
                                        <tbody>
                                          {Fechas.map((f, i) => {
                                            return (
                                              i < 3 && (
                                                <tr key={i}>
                                                  <td>
                                                    <table>
                                                      <tbody>
                                                        <tr>
                                                          <td
                                                            style={{
                                                              color: "#ffffff",
                                                              fontWeight: 600,
                                                              fontSize: "13px",
                                                              fontFamily:
                                                                "Arial, sans-serif",
                                                              lineHeight:
                                                                "17px",
                                                            }}
                                                          >
                                                            FECHAS:
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td
                                                            style={{
                                                              color: "#ffffff",
                                                              fontWeight: 400,
                                                              fontSize: "14px",
                                                              fontFamily:
                                                                "Arial, sans-serif",
                                                              lineHeight:
                                                                "17px",
                                                            }}
                                                          >
                                                            {f.Fecha
                                                              ? moment(
                                                                  f.Fecha
                                                                ).format(
                                                                  "D [de] MMMM [del] YYYY"
                                                                )
                                                              : f.Inicio &&
                                                                f.Fin
                                                              ? `Del ${moment(
                                                                  f.Inicio
                                                                ).format(
                                                                  "D [de] MMMM [del] YYYY"
                                                                )} al ${moment(
                                                                  f.Fin
                                                                ).format(
                                                                  "D [de] MMMM [del] YYYY"
                                                                )}`
                                                              : "Fecha Invalida"}
                                                          </td>
                                                        </tr>
                                                        <tr
                                                          style={{
                                                            display: "block",
                                                            margin: 0,
                                                            padding: 0,
                                                            width: "100%",
                                                          }}
                                                        >
                                                          <td height="8"></td>
                                                        </tr>

                                                        <tr>
                                                          <td
                                                            style={{
                                                              color: "#ffffff",
                                                              fontWeight: 600,
                                                              fontSize: "13px",
                                                              fontFamily:
                                                                "Arial, sans-serif",
                                                              lineHeight:
                                                                "17px",
                                                            }}
                                                          >
                                                            HORA:
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td
                                                            style={{
                                                              color: "#ffffff",
                                                              fontWeight: 400,
                                                              fontSize: "14px",
                                                              fontFamily:
                                                                "Arial, sans-serif",
                                                              lineHeight:
                                                                "17px",
                                                            }}
                                                          >
                                                            {f.Fecha
                                                              ? `${moment(
                                                                  moment(
                                                                    f.Fecha
                                                                  ).format(
                                                                    "YYYY-MM-DD "
                                                                  ) + f.Inicio
                                                                ).format(
                                                                  "h:mm a"
                                                                )} a ${moment(
                                                                  moment(
                                                                    f.Fecha
                                                                  ).format(
                                                                    "YYYY-MM-DD "
                                                                  ) + f.Fin
                                                                ).format(
                                                                  "h:mm a"
                                                                )}`
                                                              : f.Inicio &&
                                                                f.Fin
                                                              ? `${moment(
                                                                  f.Inicio
                                                                ).format(
                                                                  "h:mm a"
                                                                )} a ${moment(
                                                                  f.Fin
                                                                ).format(
                                                                  "h:mm a"
                                                                )}`
                                                              : "Fecha Invalida"}
                                                          </td>
                                                        </tr>
                                                        <tr
                                                          style={{
                                                            display: "block",
                                                            margin: 0,
                                                            padding: 0,
                                                            width: "100%",
                                                          }}
                                                        >
                                                          <td height="8"></td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </td>
                                                </tr>
                                              )
                                            );
                                          })}

                                          {Lugar.map((l, i) => {
                                            return (
                                              <tr key={i}>
                                                <td>
                                                  <table>
                                                    <tbody>
                                                      <tr>
                                                        <td
                                                          style={{
                                                            color: "#ffffff",
                                                            fontWeight: 600,
                                                            fontSize: "13px",
                                                            fontFamily:
                                                              "Arial, sans-serif",
                                                            lineHeight: "17px",
                                                          }}
                                                        >
                                                          LUGAR:
                                                        </td>
                                                      </tr>

                                                      {l.__component ===
                                                        "lugar.externo" &&
                                                        l.Ubicacion && (
                                                          <tr>
                                                            <td
                                                              style={{
                                                                color:
                                                                  "#ffffff",
                                                                fontWeight: 400,
                                                                fontSize:
                                                                  "14px",
                                                                fontFamily:
                                                                  "Arial, sans-serif",
                                                                lineHeight:
                                                                  "17px",
                                                              }}
                                                            >
                                                              {l.Ubicacion}
                                                            </td>
                                                          </tr>
                                                        )}

                                                      {l.__component ===
                                                        "lugar.google-maps" &&
                                                        l.DescripcionLugar && (
                                                          <tr>
                                                            <td
                                                              style={{
                                                                color:
                                                                  "#ffffff",
                                                                fontWeight: 400,
                                                                fontSize:
                                                                  "14px",
                                                                fontFamily:
                                                                  "Arial, sans-serif",
                                                                lineHeight:
                                                                  "17px",
                                                              }}
                                                            >
                                                              {
                                                                l.DescripcionLugar
                                                              }
                                                            </td>
                                                          </tr>
                                                        )}

                                                      {l.__component ===
                                                        "lugar.pucp" &&
                                                        l.agenda_master_lugar_pucp &&
                                                        l
                                                          .agenda_master_lugar_pucp
                                                          .Nombre && (
                                                          <tr>
                                                            <td
                                                              style={{
                                                                color:
                                                                  "#ffffff",
                                                                fontWeight: 400,
                                                                fontSize:
                                                                  "14px",
                                                                fontFamily:
                                                                  "Arial, sans-serif",
                                                                lineHeight:
                                                                  "17px",
                                                              }}
                                                            >
                                                              {
                                                                l
                                                                  .agenda_master_lugar_pucp
                                                                  .Nombre
                                                              }
                                                            </td>
                                                          </tr>
                                                        )}

                                                      <tr
                                                        style={{
                                                          display: "block",
                                                          margin: 0,
                                                          padding: 0,
                                                          width: "100%",
                                                        }}
                                                      >
                                                        <td height="8"></td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </td>
                                              </tr>
                                            );
                                          })}

                                          {(CostoPersonalizado || Costo) && (
                                            <tr>
                                              <td>
                                                <table>
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          color: "#ffffff",
                                                          fontWeight: 600,
                                                          fontSize: "13px",
                                                          fontFamily:
                                                            "Arial, sans-serif",
                                                          lineHeight: "17px",
                                                        }}
                                                      >
                                                        INVERSI&Oacute;N:
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          color: "#ffffff",
                                                          fontWeight: 400,
                                                          fontSize: "14px",
                                                          fontFamily:
                                                            "Arial, sans-serif",
                                                          lineHeight: "17px",
                                                        }}
                                                      >
                                                        {CostoPersonalizado
                                                          ? `${CostoPersonalizado}`
                                                          : `${
                                                              Costo ===
                                                              "GratuitoIngresoLibre"
                                                                ? "Ingreso libre"
                                                                : ""
                                                            }${
                                                              Costo ===
                                                              "GratuitoIngresoConRegistro"
                                                                ? "Ingreso libre previo registro"
                                                                : ""
                                                            }`}
                                                      </td>
                                                    </tr>
                                                    <tr
                                                      style={{
                                                        display: "block",
                                                        margin: 0,
                                                        padding: 0,
                                                        width: "100%",
                                                      }}
                                                    >
                                                      <td height="8"></td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          )}
                                        </tbody>
                                      </table>
                                    </td>

                                    <td width="4%"></td>
                                    <td
                                      valign="top"
                                      width="48%"
                                      style={{
                                        fontFamily: "Arial, sans-serif",
                                        fontSize: "14px",
                                        lineHeight: "23px",
                                        borderRadius: "5px",
                                        margin: 0,
                                        padding: 0,
                                      }}
                                    >
                                      <table
                                        border="0"
                                        cellPadding="0"
                                        cellSpacing="0"
                                        align="center"
                                        style={{
                                          display: "block",
                                          borderCollapse: "collapse",
                                        }}
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              valign="top"
                                              style={{ lineHeight: "0px" }}
                                            >
                                              {ImagenDestacada && (
                                                <a
                                                  href="http://www.pucp.edu.pe"
                                                  target="_blank"
                                                  rel="noreferrer"
                                                >
                                                  <img
                                                    alt="texto"
                                                    width="100%"
                                                    src={`${
                                                      variablesEntorno.AGENDA_APP_SITE +
                                                      ImagenDestacada.url
                                                    }`}
                                                  />
                                                </a>
                                              )}
                                            </td>
                                          </tr>
                                          <tr
                                            style={{
                                              display: "block",
                                              margin: 0,
                                              padding: 0,
                                              width: "100%",
                                            }}
                                          >
                                            <td height="8"></td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                  {/* <!-- botones --> */}
                                  <tr style={{ display: "flex" }}>
                                    <td
                                      valign="top"
                                      width="48%"
                                      style={{
                                        fontFamily: "Arial, sans-serif",
                                        fontSize: "14px",
                                        lineHeight: "23px",
                                        borderRadius: "5px",
                                        margin: 0,
                                        padding: 0,
                                      }}
                                    >
                                      <table
                                        border="0"
                                        cellPadding="0"
                                        cellSpacing="0"
                                        align="center"
                                        style={{
                                          display: "block",
                                          borderCollapse: "collapse",
                                        }}
                                      >
                                        <tbody>
                                          <tr
                                            style={{
                                              display: "block",
                                              margin: 0,
                                              padding: 0,
                                              width: "100%",
                                            }}
                                          >
                                            <td height="10"></td>
                                          </tr>
                                          <tr>
                                            <td
                                              align="center"
                                              className="ver-cont-btn"
                                              style={{
                                                fontFamily: "Arial",
                                                fontStyle: "normal",
                                                fontWeight: "normal",
                                                width: "100%",
                                                paddingTop: "0px",
                                                paddingBottom: "0px",
                                              }}
                                            >
                                              {LinkInscripcion && (
                                                <a
                                                  href={LinkInscripcion}
                                                  style={{
                                                    fontFamily:
                                                      "Arial, sans-serif",
                                                    borderTop: "8px",
                                                    borderRight: "35px",
                                                    borderLeft: "35px",
                                                    borderBottom: "8px",
                                                    borderStyle: "solid",
                                                    borderColor: "#2365FF",
                                                    padding: 0,
                                                    textDecoration: "none",
                                                    fontSize: "12px",
                                                    lineHeight: "12px",
                                                    textAlign: "center",
                                                    color: "#ffffff",
                                                    background: "#2365FF",
                                                    fontWeight: 400,
                                                    borderRadius: "8px",
                                                    display: "inline-block",
                                                    letterSpacing: ".5px",
                                                  }}
                                                  target="_blank"
                                                  rel="noreferrer"
                                                >
                                                  INSCRIBIRSE
                                                </a>
                                              )}
                                            </td>
                                          </tr>

                                          <tr
                                            style={{
                                              display: "block",
                                              margin: 0,
                                              padding: 0,
                                              width: "100%",
                                            }}
                                          >
                                            <td height="10"></td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>

                                    <td width="4%"></td>
                                    <td
                                      valign="top"
                                      width="48%"
                                      style={{
                                        fontFamily: "Arial, sans-serif",
                                        fontSize: "14px",
                                        lineHeight: "23px",
                                        borderRadius: "5px",
                                        margin: 0,
                                        padding: 0,
                                      }}
                                    >
                                      <table
                                        border="0"
                                        cellPadding="0"
                                        cellSpacing="0"
                                        align="center"
                                        style={{
                                          display: "block",
                                          borderCollapse: "collapse",
                                        }}
                                      >
                                        <tbody>
                                          <tr
                                            style={{
                                              display: "block",
                                              margin: 0,
                                              padding: 0,
                                              width: "100%",
                                            }}
                                          >
                                            <td height="10"></td>
                                          </tr>
                                          <tr>
                                            <td
                                              align="center"
                                              className="ver-cont-btn"
                                              style={{
                                                fontFamily: "Arial",
                                                fontStyle: "normal",
                                                fontWeight: "normal",
                                                width: "100%",
                                                paddingTop: "0px",
                                                paddingBottom: "0px",
                                              }}
                                            >
                                              <a
                                                href={`https://www.google.com/calendar/render?action=TEMPLATE&text=${Titulo}&details=${Descripcion}&location=${Lugar.map(
                                                  (l) =>
                                                    `${
                                                      l.Ubicacion
                                                        ? l.Ubicacion
                                                        : l.DescripcionLugar
                                                        ? l.DescripcionLugar
                                                        : l.agenda_master_lugar_pucp
                                                        ? l
                                                            .agenda_master_lugar_pucp
                                                            .Nombre
                                                          ? l
                                                              .agenda_master_lugar_pucp
                                                              .Nombre
                                                          : ""
                                                        : ""
                                                    }`
                                                )}&dates=${calendarInicio}%2F${calendarFin}`}
                                                target="_blank"
                                                rel="noreferrer"
                                                style={{
                                                  fontFamily:
                                                    "Arial, sans-serif",
                                                  borderTop: "8px",
                                                  borderRight: "15px",
                                                  borderLeft: "15px",
                                                  borderBottom: "8px",
                                                  borderStyle: "solid",
                                                  borderColor: "#000000",
                                                  padding: 0,
                                                  textDecoration: "none",
                                                  fontSize: "12px",
                                                  lineHeight: "12px",
                                                  textAlign: "center",
                                                  color: "#ffffff",
                                                  background: "#000000",
                                                  fontWeight: 400,
                                                  borderRadius: "8px",
                                                  display: "inline-block",
                                                  letterSpacing: ".5px",
                                                }}
                                              >
                                                AGREGAR A GOOGLE CALENDAR
                                              </a>
                                            </td>
                                          </tr>

                                          <tr
                                            style={{
                                              display: "block",
                                              margin: 0,
                                              padding: 0,
                                              width: "100%",
                                            }}
                                          >
                                            <td height="10"></td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td valign="top" height="20"></td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>

                  {/* <!-- fin info-destacado --> */}
                  {/* <!-- FIN DESTACADO AZUL--> */}

                  <tr>
                    <td height="15"></td>
                  </tr>

                  {/* <!-- texto --> */}
                  <tr>
                    <td
                      valign="top"
                      width="100%"
                      style={{ margin: 0, padding: 0 }}
                    >
                      <table
                        border="0"
                        cellPadding="0"
                        cellSpacing="0"
                        width="100%"
                        align="left"
                        style={{
                          fontFamily: "Arial, sans-serif",
                          color: "#666666",
                          fontSize: "14px",
                          lineHeight: "18px",
                          background: "#ffffff !important",
                          borderCollapse: "collapse",
                        }}
                      >
                        <tbody>
                          <tr>
                            <td style={{ margin: 0, padding: 0 }}>
                              <table
                                border="0"
                                cellPadding="0"
                                cellSpacing="0"
                                width="92%"
                                align="center"
                                style={{ borderCollapse: "collapse" }}
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      width="100%"
                                      style={{ margin: 0, padding: 0 }}
                                    >
                                      <table
                                        border="0"
                                        cellPadding="0"
                                        cellSpacing="0"
                                        align="center"
                                        style={{
                                          fontFamily: "Arial, sans-serif",
                                          fontSize: "14px",
                                          borderCollapse: "collapse",
                                        }}
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              style={{
                                                color: "#666666",
                                                fontSize: "14px",
                                                lineHeight: "18px",
                                                fontFamily: "Arial, sans-serif",
                                                margin: 0,
                                                padding: 0,
                                              }}
                                            >
                                              {Descripcion && (
                                                <div
                                                  dangerouslySetInnerHTML={{
                                                    __html: Descripcion,
                                                  }}
                                                ></div>
                                              )}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td valign="top" height="15"></td>
                                          </tr>
                                          <tr>
                                            <td
                                              style={{
                                                color: "#001056",
                                                fontSize: "15px",
                                                lineHeight: "18px",
                                                fontFamily: "Arial, sans-serif",
                                                fontWeight: 600,
                                                letterSpacing: ".5px",
                                                margin: 0,
                                                padding: 0,
                                              }}
                                            >
                                              <p
                                                style={{
                                                  margin: 0,
                                                  padding: 0,
                                                }}
                                              >
                                                COMPARTIR
                                              </p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td valign="top" height="12"></td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>

                  {/* <!-- enlaces --> */}
                  <tr style={{ borderCollapse: "collapse" }}>
                    <td
                      style={{ margin: 0, padding: 0, background: "#ffffff" }}
                    >
                      <table
                        border="0"
                        cellPadding="0"
                        cellSpacing="0"
                        width="92%"
                        align="center"
                        style={{
                          fontFamily: "Arial, sans-serif",
                          color: "#666666",
                          fontSize: "13px",
                          lineHeight: "23px",
                          borderCollapse: "collapse",
                        }}
                      >
                        <tbody>
                          <tr>
                            <td style={{ margin: 0, padding: 0 }}>
                              <table
                                border="0"
                                cellPadding="0"
                                cellSpacing="0"
                                width="100%"
                                align="top"
                                style={{ borderCollapse: "collapse" }}
                              >
                                <tbody>
                                  <tr style={{ display: "flex" }}>
                                    <td
                                      valign="top"
                                      width="48%"
                                      style={{
                                        fontFamily: "Arial, sans-serif",
                                        fontSize: "14px",
                                        lineHeight: "23px",
                                        borderRadius: "5px",
                                        margin: 0,
                                        padding: 0,
                                      }}
                                    >
                                      <table
                                        border="0"
                                        cellPadding="0"
                                        cellSpacing="0"
                                        align="center"
                                        style={{
                                          display: "block",
                                          borderCollapse: "collapse",
                                        }}
                                      >
                                        <tbody>
                                          <tr
                                            style={{
                                              display: "block",
                                              margin: 0,
                                              padding: 0,
                                              width: "100%",
                                            }}
                                          >
                                            <td height="5"></td>
                                          </tr>
                                          <tr>
                                            <td
                                              style={{ padding: 0, margin: 0 }}
                                            >
                                              <table
                                                cellPadding="0"
                                                cellSpacing="0"
                                                width="100%"
                                                style={{
                                                  msoTableLspace: "0pt",
                                                  msoTableRspace: "0pt",
                                                  borderCollapse: "collapse",
                                                  borderSpacing: "0px",
                                                }}
                                              >
                                                <tbody>
                                                  <tr
                                                    style={{
                                                      borderCollapse:
                                                        "collapse",
                                                    }}
                                                  >
                                                    <td
                                                      align="left"
                                                      valign="top"
                                                      width="15%"
                                                      style={{
                                                        margin: 0,
                                                        paddingLeft: "0px",
                                                        paddingRight: "10px",
                                                        paddingTop: "0px",
                                                        paddingBottom: "10px",
                                                        border: 0,
                                                      }}
                                                    >
                                                      <a
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        href={`https://www.facebook.com/sharer/sharer.php?u=${variablesEntorno.SITE_URL}/evento/${slug}`}
                                                        style={{
                                                          WebkitTextSizeAdjust:
                                                            "none",
                                                          msTextSizeAdjust:
                                                            "none",
                                                          msoLineHeightRule:
                                                            "exactly",
                                                          fontFamily:
                                                            "arial, helvetica, sans-serif",
                                                          fontSize: "14px",
                                                          textDecoration:
                                                            "none",
                                                          display: "block",
                                                          color: "#FFFFFF",
                                                        }}
                                                      >
                                                        <img
                                                          src="https://files.pucp.education/dci/comunicados/2021/02/04105931/share-facebook.png"
                                                          alt="twitter"
                                                          //   title
                                                          align="absmiddle"
                                                          width="40"
                                                          height="40"
                                                          style={{
                                                            display:
                                                              "inline-block !important",
                                                            border: 0,
                                                            outline: "none",
                                                            textDecoration:
                                                              "none",
                                                            msInterpolationMode:
                                                              "bicubic",
                                                            paddingBottom:
                                                              "5px",
                                                          }}
                                                        />
                                                        <br />
                                                      </a>
                                                    </td>
                                                    <td
                                                      align="left"
                                                      valign="top"
                                                      width="15%"
                                                      style={{
                                                        margin: 0,
                                                        paddingLeft: "0px",
                                                        paddingRight: "10px",
                                                        paddingTop: "0px",
                                                        paddingBottom: "10px",
                                                        border: 0,
                                                      }}
                                                    >
                                                      <a
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        href={`https://twitter.com/intent/tweet?text=${variablesEntorno.SITE_URL}/evento/${slug}`}
                                                        style={{
                                                          WebkitTextSizeAdjust:
                                                            "none",
                                                          msTextSizeAdjust:
                                                            "none",
                                                          msoLineHeightRule:
                                                            "exactly",
                                                          fontFamily:
                                                            "arial, helvetica, sans-serif",
                                                          fontSize: "14px",
                                                          textDecoration:
                                                            "none",
                                                          display: "block",
                                                          color: "#FFFFFF",
                                                        }}
                                                      >
                                                        <img
                                                          src="https://files.pucp.education/dci/comunicados/2021/02/04105934/share-twitter.png"
                                                          alt="instagram"
                                                          //   title
                                                          align="absmiddle"
                                                          width="40"
                                                          height="40"
                                                          style={{
                                                            display:
                                                              "inline-block !important",
                                                            border: 0,
                                                            outline: "none",
                                                            textDecoration:
                                                              "none",
                                                            msInterpolationMode:
                                                              "bicubic",
                                                            paddingBottom:
                                                              "5px",
                                                          }}
                                                        />
                                                        <br />
                                                      </a>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>

                                          <tr
                                            style={{
                                              display: "block",
                                              margin: 0,
                                              padding: 0,
                                              width: "100%",
                                            }}
                                          >
                                            <td height="5"></td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>

                                    <td width="4%"></td>
                                    <td
                                      valign="top"
                                      width="48%"
                                      style={{
                                        fontFamily: "Arial, sans-serif",
                                        fontSize: "14px",
                                        lineHeight: "23px",
                                        borderRadius: "5px",
                                        margin: 0,
                                        padding: 0,
                                      }}
                                    >
                                      <table
                                        border="0"
                                        cellPadding="0"
                                        cellSpacing="0"
                                        align="center"
                                        style={{
                                          display: "block",
                                          borderCollapse: "collapse",
                                        }}
                                      >
                                        <tbody>
                                          <tr
                                            style={{
                                              display: "block",
                                              margin: 0,
                                              padding: 0,
                                              width: "100%",
                                            }}
                                          >
                                            <td height="5"></td>
                                          </tr>
                                          <tr>
                                            <td
                                              align="center"
                                              className="ver-cont-btn"
                                              style={{
                                                fontFamily: "Arial",
                                                fontStyle: "normal",
                                                fontWeight: "normal",
                                                width: "100%",
                                                paddingTop: "0px",
                                                paddingBottom: "0px",
                                              }}
                                            >
                                              <a
                                                href={`${variablesEntorno.SITE_URL}/evento/${slug}`}
                                                style={{
                                                  fontFamily:
                                                    "Arial, sans-serif",
                                                  borderTop: "13px",
                                                  borderRight: "35px",
                                                  borderLeft: "35px",
                                                  borderBottom: "13px",
                                                  borderStyle: "solid",
                                                  borderColor: "#2365FF",
                                                  padding: 0,
                                                  textDecoration: "none",
                                                  fontSize: "14px",
                                                  lineHeight: "14px",
                                                  textAlign: "center",
                                                  color: "#ffffff",
                                                  background: "#2365FF",
                                                  fontWeight: 400,
                                                  borderRadius: "8px",
                                                  display: "inline-block",
                                                  letterSpacing: ".5px",
                                                }}
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                VER EVENTO EN AGENDA
                                              </a>
                                            </td>
                                          </tr>

                                          <tr
                                            style={{
                                              display: "block",
                                              margin: 0,
                                              padding: 0,
                                              width: "100%",
                                            }}
                                          >
                                            <td height="5"></td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>

                  {/* <!-- fin de enlaces --> */}

                  {/* <!-- organizadored --> */}
                  <tr>
                    <td
                      valign="top"
                      width="100%"
                      style={{ margin: 0, padding: 0 }}
                    >
                      <table
                        border="0"
                        cellPadding="0"
                        cellSpacing="0"
                        width="100%"
                        align="left"
                        style={{
                          fontFamily: "Arial, sans-serif",
                          color: "#666666",
                          fontSize: "14px",
                          lineHeight: "18px",
                          background: "#ffffff !important",
                          borderCollapse: "collapse",
                        }}
                      >
                        <tbody>
                          <tr>
                            <td style={{ margin: 0, padding: 0 }}>
                              <table
                                border="0"
                                cellPadding="0"
                                cellSpacing="0"
                                width="92%"
                                align="center"
                                style={{ borderCollapse: "collapse" }}
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      width="100%"
                                      height="1"
                                      style={{
                                        margin: 0,
                                        padding: 0,
                                        backgroundColor: "#BFC3D4",
                                      }}
                                    ></td>
                                  </tr>
                                  <tr>
                                    <td valign="top" height="20"></td>
                                  </tr>
                                  {/* {OrganizadoPor && (
                                    <tr>
                                      <td>
                                        <table>
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  color: "#001056",
                                                  fontSize: "15px",
                                                  lineHeight: "18px",
                                                  fontFamily:
                                                    "Arial, sans-serif",
                                                  fontWeight: 600,
                                                  letterSpacing: ".5px",
                                                  margin: 0,
                                                  padding: 0,
                                                }}
                                              >
                                                <p
                                                  style={{
                                                    margin: 0,
                                                    padding: 0,
                                                  }}
                                                >
                                                  ORGANIZADO POR
                                                </p>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td valign="top" height="12"></td>
                                            </tr>
                                            <tr>
                                              <td
                                                style={{
                                                  color: "#666666",
                                                  fontSize: "14px",
                                                  lineHeight: "18px",
                                                  fontFamily:
                                                    "Arial, sans-serif",
                                                  fontWeight: 400,
                                                  margin: 0,
                                                  padding: 0,
                                                }}
                                              >
                                                <div
                                                  dangerouslySetInnerHTML={{
                                                    __html: OrganizadoPor,
                                                  }}
                                                ></div>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td valign="top" height="15"></td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  )} */}
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  {/* <!-- fin de organizadores--> */}

                  {/* <!-- mas info--> */}
                  <tr>
                    <td
                      valign="top"
                      width="100%"
                      style={{ margin: 0, padding: 0 }}
                    >
                      <table
                        border="0"
                        cellPadding="0"
                        cellSpacing="0"
                        width="100%"
                        align="left"
                        style={{
                          fontFamily: "Arial, sans-serif",
                          color: "#001056",
                          fontSize: "14px",
                          lineHeight: "18px",
                          background: "#ffffff !important",
                          borderCollapse: "collapse",
                        }}
                      >
                        <tbody>
                          {MasInformacion.length > 0 && (
                            <tr>
                              <td width="20" height="100"></td>
                              <td width="92%" style={{ margin: 0, padding: 0 }}>
                                <table
                                  border="0"
                                  cellPadding="0"
                                  cellSpacing="0"
                                  align="left"
                                  width="100%"
                                  style={{
                                    fontFamily: "Arial, sans-serif",
                                    fontSize: "14px",
                                    borderCollapse: "collapse",
                                  }}
                                >
                                  <tbody>
                                    <tr style={{ borderCollapse: "collapse" }}>
                                      <td
                                        style={{
                                          margin: 0,
                                          padding: 0,
                                          background: "#CCF3F7",
                                        }}
                                      >
                                        <table
                                          border="0"
                                          cellPadding="0"
                                          cellSpacing="0"
                                          width="92%"
                                          align="center"
                                          style={{
                                            fontFamily: "Arial, sans-serif",
                                            color: "#7C7C7C",
                                            fontSize: "13px",
                                            lineHeight: "23px",
                                            borderCollapse: "collapse",
                                            backgroundColor: "#CCF3F7",
                                          }}
                                        >
                                          <tbody>
                                            <tr>
                                              <td valign="top" height="20"></td>
                                            </tr>
                                            <tr>
                                              <td
                                                style={{
                                                  color: "#001056",
                                                  fontSize: "15px",
                                                  lineHeight: "18px",
                                                  fontFamily:
                                                    "Arial, sans-serif",
                                                  fontWeight: 600,
                                                  letterSpacing: ".5px",
                                                  margin: 0,
                                                  padding: 0,
                                                }}
                                              >
                                                <p
                                                  style={{
                                                    margin: 0,
                                                    padding: 0,
                                                  }}
                                                >
                                                  MÁS INFORMACION
                                                </p>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td
                                                valign="top"
                                                height="5"
                                                colSpan="2"
                                              ></td>
                                            </tr>
                                            <tr>
                                              <td
                                                style={{
                                                  margin: 0,
                                                  padding: 0,
                                                }}
                                              >
                                                <table
                                                  border="0"
                                                  cellPadding="0"
                                                  cellSpacing="0"
                                                  width="100%"
                                                  align="top"
                                                  style={{
                                                    borderCollapse: "collapse",
                                                  }}
                                                >
                                                  <tbody>
                                                    <tr
                                                      style={{
                                                        display: "flex",
                                                      }}
                                                    >
                                                      <td
                                                        valign="top"
                                                        width="48%"
                                                        style={{
                                                          fontFamily:
                                                            "Arial, sans-serif",
                                                          fontSize: "14px",
                                                          lineHeight: "23px",
                                                          borderRadius: "5px",
                                                          margin: 0,
                                                          padding: 0,
                                                        }}
                                                      >
                                                        <table
                                                          border="0"
                                                          cellPadding="0"
                                                          cellSpacing="0"
                                                          align="center"
                                                          style={{
                                                            display: "block",
                                                            borderCollapse:
                                                              "collapse",
                                                            color: "#001056",
                                                          }}
                                                        >
                                                          <tbody>
                                                            {m1.map((m) => {
                                                              return (
                                                                <tr key={m.id}>
                                                                  <td>
                                                                    <table>
                                                                      <tbody>
                                                                        <tr
                                                                          style={{
                                                                            display:
                                                                              "block",
                                                                            margin: 0,
                                                                            padding: 0,
                                                                            width:
                                                                              "100%",
                                                                            border: 0,
                                                                          }}
                                                                        >
                                                                          <td
                                                                            height="5"
                                                                            colSpan="2"
                                                                          ></td>
                                                                        </tr>
                                                                        <tr>
                                                                          <td
                                                                            valign="middle"
                                                                            width="25"
                                                                            style={{
                                                                              lineHeight:
                                                                                "0px",
                                                                            }}
                                                                          >
                                                                            {m.TipoLink ? (
                                                                              m.TipoLink.toLowerCase() ===
                                                                              "correo" ? (
                                                                                <img
                                                                                  alt="texto"
                                                                                  width="16"
                                                                                  src="https://files.pucp.education/dci/comunicados/2021/02/04105918/icono-correo.png"
                                                                                  style={{
                                                                                    borderRadius:
                                                                                      "4px",
                                                                                    borderBottomLeftRadius: 0,
                                                                                    borderBottomRightRadius: 0,
                                                                                  }}
                                                                                />
                                                                              ) : m.TipoLink.toLowerCase() ===
                                                                                "twitter" ? (
                                                                                <img
                                                                                  alt="texto"
                                                                                  width="16"
                                                                                  src="https://files.pucp.education/dci/comunicados/2021/02/04105924/icono-twitter.png"
                                                                                  style={{
                                                                                    borderRadius:
                                                                                      "4px",
                                                                                    borderBottomLeftRadius: 0,
                                                                                    borderBottomRightRadius: 0,
                                                                                  }}
                                                                                />
                                                                              ) : m.TipoLink.toLowerCase() ===
                                                                                "facebook" ? (
                                                                                <img
                                                                                  alt="texto"
                                                                                  width="16"
                                                                                  src="https://files.pucp.education/dci/comunicados/2021/02/04105919/icono-facebook.png"
                                                                                  style={{
                                                                                    borderRadius:
                                                                                      "4px",
                                                                                    borderBottomLeftRadius: 0,
                                                                                    borderBottomRightRadius: 0,
                                                                                  }}
                                                                                />
                                                                              ) : m.TipoLink.toLowerCase() ===
                                                                                "instagram" ? (
                                                                                <img
                                                                                  alt="texto"
                                                                                  width="16"
                                                                                  src="https://files.pucp.education/dci/comunicados/2021/02/04105920/icono-instagram.png"
                                                                                  style={{
                                                                                    borderRadius:
                                                                                      "4px",
                                                                                    borderBottomLeftRadius: 0,
                                                                                    borderBottomRightRadius: 0,
                                                                                  }}
                                                                                />
                                                                              ) : m.TipoLink.toLowerCase() ===
                                                                                "link" ? (
                                                                                <img
                                                                                  alt="texto"
                                                                                  width="16"
                                                                                  src="https://files.pucp.education/dci/comunicados/2021/02/04105926/icono-web.png"
                                                                                  style={{
                                                                                    borderRadius:
                                                                                      "4px",
                                                                                    borderBottomLeftRadius: 0,
                                                                                    borderBottomRightRadius: 0,
                                                                                  }}
                                                                                />
                                                                              ) : (
                                                                                <img
                                                                                  alt="texto"
                                                                                  width="16"
                                                                                  src="https://files.pucp.education/dci/comunicados/2021/02/04105926/icono-web.png"
                                                                                  style={{
                                                                                    borderRadius:
                                                                                      "4px",
                                                                                    borderBottomLeftRadius: 0,
                                                                                    borderBottomRightRadius: 0,
                                                                                  }}
                                                                                />
                                                                              )
                                                                            ) : (
                                                                              <img
                                                                                alt="texto"
                                                                                width="16"
                                                                                src="https://files.pucp.education/dci/comunicados/2021/02/04105926/icono-web.png"
                                                                                style={{
                                                                                  borderRadius:
                                                                                    "4px",
                                                                                  borderBottomLeftRadius: 0,
                                                                                  borderBottomRightRadius: 0,
                                                                                }}
                                                                              />
                                                                            )}
                                                                          </td>
                                                                          <td valign="top">
                                                                            <p
                                                                              style={{
                                                                                marginTop:
                                                                                  "5px",
                                                                                marginBottom:
                                                                                  "5px",
                                                                                height:
                                                                                  "100%",
                                                                              }}
                                                                            >
                                                                              <a
                                                                                href={
                                                                                  m.TipoLink?.toLowerCase() ===
                                                                                  "correo"
                                                                                    ? `mailto:${m.Link}`
                                                                                    : `${m.Link}`
                                                                                }
                                                                                target="_blank"
                                                                                rel="noreferrer"
                                                                              >
                                                                                {m.Descripcion
                                                                                  ? m.Descripcion
                                                                                  : m.Link}
                                                                              </a>
                                                                            </p>
                                                                          </td>
                                                                        </tr>
                                                                      </tbody>
                                                                    </table>
                                                                  </td>
                                                                </tr>
                                                              );
                                                            })}

                                                            {/* src="https://files.pucp.education/dci/comunicados/2021/02/04105923/icono-telefono.png" */}
                                                            {/* src="https://files.pucp.education/dci/comunicados/2021/02/04105918/icono-correo.png" */}
                                                            {/* src="https://files.pucp.education/dci/comunicados/2021/02/04105920/icono-instagram.png" */}
                                                            {/* src="https://files.pucp.education/dci/comunicados/2021/02/04105922/icono-linkedin.png" */}
                                                          </tbody>
                                                        </table>
                                                      </td>

                                                      <td width="4%"></td>
                                                      <td
                                                        valign="top"
                                                        width="48%"
                                                        style={{
                                                          fontFamily:
                                                            "Arial, sans-serif",
                                                          fontSize: "14px",
                                                          lineHeight: "23px",
                                                          borderRadius: "5px",
                                                          margin: 0,
                                                          padding: 0,
                                                        }}
                                                      >
                                                        <table
                                                          border="0"
                                                          cellPadding="0"
                                                          cellSpacing="0"
                                                          align="center"
                                                          style={{
                                                            display: "block",
                                                            borderCollapse:
                                                              "collapse",
                                                            color: "#001056",
                                                          }}
                                                        >
                                                          <tbody>
                                                            {m2.map((m) => {
                                                              return (
                                                                <tr key={m.id}>
                                                                  <td>
                                                                    <table>
                                                                      <tbody>
                                                                        <tr
                                                                          style={{
                                                                            display:
                                                                              "block",
                                                                            margin: 0,
                                                                            padding: 0,
                                                                            width:
                                                                              "100%",
                                                                            border: 0,
                                                                          }}
                                                                        >
                                                                          <td
                                                                            height="5"
                                                                            colSpan="2"
                                                                          ></td>
                                                                        </tr>
                                                                        <tr>
                                                                          <td
                                                                            valign="middle"
                                                                            width="25"
                                                                            style={{
                                                                              lineHeight:
                                                                                "0px",
                                                                            }}
                                                                          >
                                                                            {m.TipoLink ? (
                                                                              m.TipoLink.toLowerCase() ===
                                                                              "correo" ? (
                                                                                <img
                                                                                  alt="texto"
                                                                                  width="16"
                                                                                  src="https://files.pucp.education/dci/comunicados/2021/02/04105918/icono-correo.png"
                                                                                  style={{
                                                                                    borderRadius:
                                                                                      "4px",
                                                                                    borderBottomLeftRadius: 0,
                                                                                    borderBottomRightRadius: 0,
                                                                                  }}
                                                                                />
                                                                              ) : m.TipoLink.toLowerCase() ===
                                                                                "twitter" ? (
                                                                                <img
                                                                                  alt="texto"
                                                                                  width="16"
                                                                                  src="https://files.pucp.education/dci/comunicados/2021/02/04105924/icono-twitter.png"
                                                                                  style={{
                                                                                    borderRadius:
                                                                                      "4px",
                                                                                    borderBottomLeftRadius: 0,
                                                                                    borderBottomRightRadius: 0,
                                                                                  }}
                                                                                />
                                                                              ) : m.TipoLink.toLowerCase() ===
                                                                                "facebook" ? (
                                                                                <img
                                                                                  alt="texto"
                                                                                  width="16"
                                                                                  src="https://files.pucp.education/dci/comunicados/2021/02/04105919/icono-facebook.png"
                                                                                  style={{
                                                                                    borderRadius:
                                                                                      "4px",
                                                                                    borderBottomLeftRadius: 0,
                                                                                    borderBottomRightRadius: 0,
                                                                                  }}
                                                                                />
                                                                              ) : m.TipoLink.toLowerCase() ===
                                                                                "instagram" ? (
                                                                                <img
                                                                                  alt="texto"
                                                                                  width="16"
                                                                                  src="https://files.pucp.education/dci/comunicados/2021/02/04105920/icono-instagram.png"
                                                                                  style={{
                                                                                    borderRadius:
                                                                                      "4px",
                                                                                    borderBottomLeftRadius: 0,
                                                                                    borderBottomRightRadius: 0,
                                                                                  }}
                                                                                />
                                                                              ) : m.TipoLink.toLowerCase() ===
                                                                                "link" ? (
                                                                                <img
                                                                                  alt="texto"
                                                                                  width="16"
                                                                                  src="https://files.pucp.education/dci/comunicados/2021/02/04105926/icono-web.png"
                                                                                  style={{
                                                                                    borderRadius:
                                                                                      "4px",
                                                                                    borderBottomLeftRadius: 0,
                                                                                    borderBottomRightRadius: 0,
                                                                                  }}
                                                                                />
                                                                              ) : (
                                                                                <img
                                                                                  alt="texto"
                                                                                  width="16"
                                                                                  src="https://files.pucp.education/dci/comunicados/2021/02/04105926/icono-web.png"
                                                                                  style={{
                                                                                    borderRadius:
                                                                                      "4px",
                                                                                    borderBottomLeftRadius: 0,
                                                                                    borderBottomRightRadius: 0,
                                                                                  }}
                                                                                />
                                                                              )
                                                                            ) : (
                                                                              <img
                                                                                alt="texto"
                                                                                width="16"
                                                                                src="https://files.pucp.education/dci/comunicados/2021/02/04105926/icono-web.png"
                                                                                style={{
                                                                                  borderRadius:
                                                                                    "4px",
                                                                                  borderBottomLeftRadius: 0,
                                                                                  borderBottomRightRadius: 0,
                                                                                }}
                                                                              />
                                                                            )}
                                                                          </td>
                                                                          <td valign="top">
                                                                            <p
                                                                              style={{
                                                                                marginTop:
                                                                                  "5px",
                                                                                marginBottom:
                                                                                  "5px",
                                                                              }}
                                                                            >
                                                                              <a
                                                                                href={
                                                                                  m.TipoLink?.toLowerCase() ===
                                                                                  "correo"
                                                                                    ? `mailto:${m.Link}`
                                                                                    : `${m.Link}`
                                                                                }
                                                                                target="_blank"
                                                                                rel="noreferrer"
                                                                              >
                                                                                {m.Descripcion
                                                                                  ? m.Descripcion
                                                                                  : m.Link}
                                                                              </a>
                                                                            </p>
                                                                          </td>
                                                                        </tr>
                                                                      </tbody>
                                                                    </table>
                                                                  </td>
                                                                </tr>
                                                              );
                                                            })}
                                                          </tbody>
                                                        </table>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td
                                                valign="top"
                                                height="25"
                                                colSpan="2"
                                              ></td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              <td width="20" height="100"></td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </td>
                  </tr>

                  <tr>
                    <td height="20" style={{ background: "#ffffff" }}></td>
                  </tr>
                  {/* <!-- fin de mas info--> */}
                </tbody>
              </table>
            </td>
          </tr>

          {/* <!-- footer --> */}
          <tr>
            <td
              valign="top"
              style={{ background: "#ffffff", margin: 0, padding: 0 }}
            >
              <table
                border="0"
                cellPadding="0"
                cellSpacing="0"
                width="100%"
                style={{ borderCollapse: "collapse" }}
              >
                <tbody>
                  <tr style={{ borderCollapse: "collapse" }}>
                    <td
                      style={{
                        margin: 0,
                        paddingLeft: "30px",
                        paddingRight: "30px",
                        paddingBottom: 0,
                        paddingTop: 0,
                        backgroundColor: "#E6E8EF",
                      }}
                    >
                      <table
                        cellPadding="0"
                        cellSpacing="0"
                        style={{
                          msoTableLspace: "0pt",
                          msoTableRspace: "0pt",
                          borderCollapse: "collapse",
                          borderSpacing: "0px",
                          textAlign: "center",
                          width: "100%",
                        }}
                      >
                        <tbody>
                          <tr>
                            <td height="20"></td>
                          </tr>
                          <tr style={{ borderCollapse: "collapse" }}>
                            <td
                              align="center"
                              style={{
                                fontSize: "11px",
                                color: "#555555",
                                lineHeight: "14px",
                                fontFamily: "arial, helvetica, sans-serif",
                                padding: 0,
                                textAlign: "center",
                              }}
                            >
                              <p>
                                Si usted no desea recibir estos e-mails
                                nuevamente, por favor envíe un correo
                                solicitando ser dado de baja de la lista de
                                suscripción. Mientras se le permita cancelar el
                                envío, este e-mail no podrá ser considerado
                                SPAM, según lo establecido por la Ley N° 29246,
                                la misma que regula el uso del correo
                                electrónico comercial no solicitado (SPAM).
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td height="20"></td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          {/* <!-- fin de footer --> */}
        </tbody>
      </table>

      <table>
        <tbody>
          <tr>
            <td height="20px"></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default BrochureScreen;
